.header {
  width: 100%;
  height: 66rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background)); 
}

.header .header-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.header .header-content #logo {
  width: 30rem;
  height: 30rem;
  margin-top: 1rem;
}

.header .header-content .fotos {
  width: 87%;
  height: 16rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;  
}

.header .header-content .fotos #ingrid {
  width: 14.62rem;
  height: 17rem;  
}

.header .header-content .fotos #jaime {
  width: 14.62rem;
  height: 17rem; 
}

.header .header-content .text-box {
  width: 92%;
  height: 7rem;
  background: var(--color-background-light);
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .header-content .text-box .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;  
}

.header .header-content .text-box .info strong {
  font: 600 1.3rem barlow;
  color: var(--color-text-blue);
}

.header .header-content .text-box .info p {
  font: 600 1rem barlow;
  color: var(--color-text-blue);
}

.header .header-content button {
  width: 20rem;
  height: 4rem;
  background: linear-gradient(to right, var(--color-background-dark), var(--color-background));
  color: var(--color-text-white);
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  font: 600 2rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;
  margin-top: 4rem;
}

.header .header-content button:hover {
  color: var(--color-text-shadow);
}

@media (min-width: 600px) {
  .header {
    height: 74rem;
  }

  .header .header-content .fotos {
    width: 85%;
    height: 16rem;
    margin-top: 4rem;    
  }
  
  .header .header-content .fotos #ingrid {
    width: 19.82rem;
    height: 23rem;  
  } 
  
  .header .header-content .fotos #jaime {
    width: 19.82rem;
    height: 23rem; 
  } 

  .header .header-content .text-box {
    height: 10rem;
    border-radius: 4rem;   
  }

  .header .header-content .text-box .info {
    margin-left: 3.5rem;
    margin-right: 4.2rem;  
  }

  .header .header-content .text-box .info strong {
    font: 600 1.8rem barlow;   
  }
  
  .header .header-content .text-box .info p {
    font: 600 1.3rem barlow;
    margin-top: 0.3rem;   
  }
}

@media (min-width: 800px) {
  .header {
    height: 65rem;
  }

  .header .header-content #logo {
    width: 50rem;
    height: 50rem;
    margin-top: 0;  
  }

  .header .header-content .fotos {
    width: 85%;
    height: 16rem;
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 23%);
    z-index: 0;     
  }
  
  .header .header-content .fotos #ingrid {
    width: 25rem;
    height: 29rem;  
  } 
  
  .header .header-content .fotos #jaime {
    width: 25rem;
    height: 29rem; 
  } 

  .header .header-content .text-box {
    height: 11rem;
    border-radius: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 175%);
    z-index: 1;
    border-style: solid;
    border-color: var(--color-background); 
  }

  .header .header-content .text-box .info {
    margin-left: 5.5rem;
    margin-right: 6.5rem;  
  }

  .header .header-content .text-box .info strong {
    font: 600 1.8rem barlow;   
  }
  
  .header .header-content .text-box .info p {
    font: 600 1.3rem barlow;
    margin-top: 0.3rem;   
  }

  .header .header-content button {
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 620%);
    z-index: 2;  
  }
}

@media (min-width: 1200px) {
  .header {
    height: 75rem;
  }

  .header .header-content #logo {
    width: 60rem;
    height: 60rem;
    margin-top: 0;  
  }

  .header .header-content .fotos {
    width: 85%;
    height: 30rem;
    margin-top: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -36%);
    z-index: 0;   
  }
  
  .header .header-content .fotos #ingrid {
    width: 31.03rem;
    height: 36rem;  
  } 
  
  .header .header-content .fotos #jaime {
    width: 31.03rem;
    height: 36rem; 
  } 

  .header .header-content .text-box {
    height: 13rem;
    z-index: 1;        
  }

  .header .header-content .text-box .info {
    margin-left: 8rem;
    margin-right: 8rem;    
  }

  .header .header-content .text-box .info strong {
    font: 600 2.4rem barlow;   
  }
  
  .header .header-content .text-box .info p {
    font: 600 2rem barlow;
    margin-top: 0.3rem;   
  }

  .header .header-content button {
    width: 25rem;
    height: 6rem;    
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 460%);
    z-index: 2;  
  }
}

@media (min-width: 1500px) {
  .header .header-content #logo {
    width: 80rem;
    height: 80rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    z-index: 0;     
  }

  .header .header-content .fotos {
    width: 80%;
    z-index: 1;   
  }
  
  .header .header-content .fotos #ingrid {
    width: 37.06rem;
    height: 43rem;  
  } 
  
  .header .header-content .fotos #jaime {
    width: 37.06rem;
    height: 43rem; 
  } 

  .header .header-content .text-box {
    width: 85%;
    height: 13rem;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 170%);       
  }

  .header .header-content .text-box .info {
    margin-left: 11rem;
    margin-right: 11rem;    
  }

  .header .header-content .text-box .info strong {
    font: 600 2.4rem barlow;   
  }
  
  .header .header-content .text-box .info p {
    font: 600 2rem barlow;
    margin-top: 0.3rem;   
  }

  .header .header-content button {
    width: 25rem;
    height: 6rem;    
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 460%);
    z-index: 3;  
  }
}