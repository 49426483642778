.about {
  width: 100%;
  height: 200rem;  
  background: var(--color-background-light);
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}

.about .about-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.about .about-content .text-box {
  width: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}

.about .about-content .text-box strong {
  font: 600 1.8rem barlow;
  color: var(--color-text-blue);
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.about .about-content .text-box p {
  font: 600 1.6rem barlow;
  color: var(--color-text-blue);
  text-align: center;
  margin-bottom: 0.5rem;
}

.about .about-content .text-box .lista {
  width: 100%;
  margin-top: 1rem;
}

.about .about-content .text-box li {
  font: 600 1.6rem barlow;
  color: var(--color-text-blue);
  margin-left: 2rem;
}

.about .about-content .text-box .lista p {
  text-align: left;
}

@media (min-width: 600px) {
  .about {    
    height: 160rem;    
  }

  .about .about-content .text-box {
    width: 50rem;    
  }

  .about .about-content .text-box strong {
    margin-bottom: 0.8rem;
  }

  .about .about-content .text-box p {
    line-height: 2rem;
    margin-bottom: 0.8rem;
  }
}

@media (min-width: 1200px) {
  .about {
    height: 90rem;
  }

  .about .about-content {    
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;  
  }

  .about .about-content .text-box {
    width: 50rem;
    margin-top: 4rem;    
  }
}