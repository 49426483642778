.mission {
  width: 100%;
  height: 25rem;  
  background: var(--color-background-light);
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}

.mission .mission-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mission .mission-content .text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.mission .mission-content .text-box p {
  font: 500 1.3rem barlow;
  color: var(--color-text-blue);
  text-shadow: 1px 1px var(--color-text-shadow);
}

.mission .mission-content strong {
  font: 700 3rem barlow;
  color: var(--color-text-blue);
  text-shadow: 1px 1px var(--color-text-shadow);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .mission {
    height: 35rem;    
  }

  .mission .mission-content .text-box p {
    font: 500 2rem barlow;      
  }
  
  .mission .mission-content strong {
    font: 700 4rem barlow;
    margin-top: 2rem;
    text-shadow: 2px 2px var(--color-text-shadow);   
  }
}

@media (min-width: 1200px) {
  .mission {
    height: 65rem;    
  }

  .mission .mission-content .text-box p {
    font: 600 3.8rem barlow;
    text-shadow: 2px 2px var(--color-text-shadow);        
  }
  
  .mission .mission-content strong {
    font: 700 6rem barlow;
    margin-top: 3rem;   
  }
}
