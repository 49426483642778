.about-header {
  width: 100%;
  height: 50rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background)); 
}

.about-header .about-header-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.about-header .about-header-content #logo {
  width: 30rem;
  height: 30rem;
  margin-top: 1rem;
  margin-bottom: 3rem;  
}

.about-header .about-header-content .fotos {
  width: 87%;
  height: 16rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;  
}

.about-header .about-header-content .fotos #ingrid {
  width: 15rem;
  height: 16.95rem;  
}

.about-header .about-header-content .fotos #jaime {
  width: 16.56rem;
  height: 18rem;  
}

.about-header .about-header-content .text-box {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 55%);
  z-index: 0; 
}

.about-header .about-header-content .text-box p {
  font: 600 1.2rem barlow;
  color: var(--color-text-white);
}

@media (min-width: 600px) {
  .about-header {
    height: 54rem;
  }

  .about-header .about-header-content .fotos {
    width: 85%;
    height: 16rem;
    margin-top: 5rem;    
  }
  
  .about-header .about-header-content .fotos #ingrid {
    width: 20rem;
    height: 22.72rem;  
  } /*0.88*/
  
  .about-header .about-header-content .fotos #jaime {
    width: 22.54rem;
    height: 24.5rem; 
  } /*0.92*/

  .about-header .about-header-content .text-box {
    transform: translate(-50%, -30%);   
  }

  .about-header .about-header-content .text-box p {
    font: 600 1.4rem barlow;    
  }
}

@media (min-width: 800px) {
  .about-header {
    height: 70rem;
  }

  .about-header .about-header-content #logo {
    width: 50rem;
    height: 50rem;
    margin-top: 0;
    margin-bottom: 10rem; 
  }

  .about-header .about-header-content .fotos {
    width: 85%;
    height: 16rem;
    margin-top: 0;    
  }
  
  .about-header .about-header-content .fotos #ingrid {
    width: 26rem;
    height: 29.54rem;  
  } /*0.88*/
  
  .about-header .about-header-content .fotos #jaime {
    width: 29.44rem;
    height: 32rem; 
  } /*0.92*/

  .about-header .about-header-content .text-box {    
    transform: translate(-50%, 10%);   
  }

  .about-header .about-header-content .text-box p {
    font: 600 1.7rem barlow;   
  }
}

@media (min-width: 1200px) {
  .about-header {
    height: 75rem;
  }

  .about-header .about-header-content #logo {
    width: 60rem;
    height: 60rem;
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    z-index: 0;  
  }

  .about-header .about-header-content .fotos {
    width: 85%;
    height: 30rem;
    margin-top: 0;     
  }
  
  .about-header .about-header-content .fotos #ingrid {
    width: 33rem;
    height: 37.5rem;  
  } /*0.88*/
  
  .about-header .about-header-content .fotos #jaime {
    width: 37.26rem;
    height: 40.5rem; 
  } /*0.92*/

  .about-header .about-header-content .text-box {    
    transform: translate(-50%, 190%);   
  }
}

@media (min-width: 1500px) {
  .about-header .about-header-content #logo {
    width: 80rem;
    height: 80rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    z-index: 0;     
  }

  .about-header .about-header-content .fotos {
    width: 80%;     
  }
  
  .about-header .about-header-content .fotos #ingrid {
    width: 40rem;
    height: 45.45rem;  
  } /*0.88*/
  
  .about-header .about-header-content .fotos #jaime {
    width: 44.62rem;
    height: 48.5rem; 
  } /*0.92*/

  .about-header .about-header-content .text-box {    
    transform: translate(-50%, 320%);   
  }

  .about-header .about-header-content .text-box p {
    font: 600 1.8rem barlow;    
  }
}