.projects {
  width: 100%;
  height: 270rem;  
  background: var(--color-background-light);
  background-image: url('../../../assets/images/background_projects.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}

.projects .projects-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.projects .projects-content .projects-main {
  width: 90%;
  height: 95%;
  border-style: solid;
  border-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .projects-content .projects-main .items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;   
}

.projects .projects-content .projects-main .items .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .projects-content .projects-main .items .item {
  width: 28rem;
  height: 20.58rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.projects .projects-content .projects-main .items a {
  text-decoration-color: var(--color-text-blue);
}

.projects .projects-content .projects-main .items .item img {
  width: 100%;
  height: 82%;  
}

.projects .projects-content .projects-main .items .item .project-title {
  width: 100%;
  height: 6rem;
  background: var(--color-text-box);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .projects-content .projects-main .items .item .project-title #link-figuras {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .projects-content .projects-main .items .item .project-title strong {
  font: 600 1.8rem barlow;
  color: var(--color-text-blue);
  text-align: center;
  margin-top: 0;
}

.projects .projects-content .projects-main .items .item .project-title #mapa-figuras {
  font: 600 1.7rem barlow;
}

@media (min-width: 600px) {
  .projects {
    height: 330rem;
  }

  .projects .projects-content .projects-main .items .item {
    width: 35rem;
    height: 25.73rem;   
  }

  .projects .projects-content .projects-main .items .item .project-title strong {
    font: 600 2.2rem barlow;   
  }

  .projects .projects-content .projects-main .items .item .project-title #mapa-figuras {
    font: 600 2.1rem barlow;
  }
}

@media (min-width: 1200px) {
  .projects {
    height: 220rem;
  }

  .projects .projects-content .projects-main {
    width: 95%;
    height: 95%;    
  }

  .projects .projects-content .projects-main .items .item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }  

  .projects .projects-content .projects-main .items .item {
    width: 45rem;
    height: 33.08rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;  
  }

  .projects .projects-content .projects-main .items .item .project-title strong {
    font: 600 2.8rem barlow;   
  }

  .projects .projects-content .projects-main .items .item .project-title #mapa-figuras {
    font: 700 2.5rem barlow;
  }
}
